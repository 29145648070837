.dynamic-form {
  max-width: 1400px;
  margin: auto;
}
.formik-row {
    display: grid;
    grid-template-columns: 20% 1fr 35%;
    gap: 20px;
    margin-bottom: 6px;
    margin-top: 6px;
}

.formik-radio {
    display: grid;
    grid-template-columns: 20% 1fr 35%;
    grid-gap: 10px; /* Adjust the gap between items */
  }
  
  .column1 {
    grid-column: 1 / 2; /* Spanning from column 1 to 2 */
  }
  
  .column2 {
    grid-column: 2 / 3; /* Spanning from column 2 to 4 */
    display: grid;
    grid-template-columns: 40px 1fr;
    cursor: pointer;
  }
  .column3 {
    grid-column: 3 / 4; /* Spanning from column 2 to 4 */
  }

  @media (max-width: 800px) {
    .column1 {
      grid-column: 1 / -1;
    }
    .column2, .bootstrap-select {
      grid-column: 1 / -1;
    }
    .column3 {
      grid-column: 1 / -1;
      margin-left: 50px;
      font-size: .9em;
      color: #666;
    }
    .formik-row {
      margin-bottom: 20px;
    }
  }

  .form-control.phone {
    max-width: 300px;
  }

  .form-control.zip_code {
    max-width: 120;
    text-align: center;
    margin-right: 10px;;
  }

  .lopd {
    margin-bottom: 15px;
  }

  .lopd .titulo {
    font-size: 2em;
    text-align: center;
    padding: 20px 0px;
  }

  .lopd .tabla-lopd {
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 12px;
  }
  .lopd .tabla-lopd .left {
    text-align: right;
    font-weight: bold;
    padding-right: 20px;
  }

  .lopd .agreement-container {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
  }

  .agreement .form-check {
    white-space: nowrap;
  }
  .agreement .form-check label {
    white-space: break-spaces;
  }
  