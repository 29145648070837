.enviar-sol{
    z-index: -1000;
    /* background-color: red; */
    
}
@media (min-width: 1300px) {
    body{
    }

    footer{
    }
}


#footer {
    z-index: 1000; 
    margin-left: 300px;
}


@media (max-width: 576px) {

    #header {
        /*
        position: absolute;
        top: 0;
        left:0;
        width: 40px;
        height: 30px;
*/
    }

}

@media (max-width: 1300px) {
    html{
        min-height: 100%;
    }
    body{
        height: 1100px;
    }
    #footer {
        margin-right: 70px;
        margin-left: 120px;
    }

    #header {
        margin-left: 40px;
    }
}

@media (max-width: 800px) {
    body{
    }
    
    #footer {
        z-index: 1000; 
        margin-left: 40px;
    }
    
    #header {
        /* display: none;*/
        margin-left: 40px;
    }
    #logo {
        display: none;
    }
}





.background {
    /* z-index: -2; */
    overflow-x: hidden;
}


/*.background .sun {
    width: 12%;
    right: 25vw;
    top: 7%;
} */

.permanent .sun {
    display: none;
}

/* .foreground {
    text-shadow: 0 0 5px #ffffff82;
} */

.foreground .sun {
    width: 10vw;
    right: 25vw;
    top: 7%;
    cursor: pointer;
    transform: rotate(0deg);
}

.foreground .sun:hover {
    animation-name: sun;
    animation-duration: 1s;
}

@keyframes sun {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.bg-element {
    opacity: 0.75;
    transition: opacity 0.1s linear;
}

.pag-1.casa .bg-element.casa,
.pag-1.edifici .bg-element.edifici,
.pag-1.altres .bg-element.altres {
    opacity: 1;
}

.pag-1.casa .bg-element.edifici,
.pag-1.casa .bg-element.altres,
.pag-1.edifici .bg-element.casa,
.pag-1.edifici .bg-element.altres,
.pag-1.altres .bg-element.casa,
.pag-1.altres .bg-element.edifici {
    opacity: 0.4;
}

.pag-4 .cases,
.pag-4 .edificis,
.pag-4 .altres {
    display: none;
}

.pag-4.casa .cases,
.pag-4.edifici .edificis,
.pag-4.altres .altres {
    display: block;
}

.pag-4 .background .elem {
    opacity: 0;
}

.pag-4 .background.elem1 .elem1,
.pag-4 .background.elem2 .elem2,
.pag-4 .background.elem3 .elem3,
.pag-4 .background.elem4 .elem4,
.pag-4 .background.elem5 .elem5 {
    opacity: 1;
}

.foreground .content-page {
    margin-top: -10%;
}

.foreground .content-page.content-page-6 {
    /*background-color: rgba(200, 231, 224, 0.68);*/
    padding: 50px;
    margin-top: 0px;
}

.foreground .content-page.content-page-6 h4 {
    margin: 20px 0px;
}

.foreground .content-page.content-page-6 button {
    margin-bottom: 20px;
    margin-top: 10px;
}

.form-check-inline {
    margin: 0.25rem 0.5rem;
}

.form-check-inline {
    margin: 0.25rem 0.5rem;
}

.btn.btn-dark.btn-checkbox.selected {
    background-color: gray;
}

.pag-1-radio.btn-check.selected {
    background-color: gray;
}

.btn-container {
    flex-wrap: wrap;
    width: 75vw;
    margin: 0 auto;
    max-height: 90vw;
    overflow: auto;
}

#main {
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

input[type="text"]:focus {
    border: 0px;
    outline: none;
}

input[type="text"]::placeholder {
    color: black;
}

input[type="email"]::placeholder {
    color: black;
}

#rgpd {
    width: auto;
    border: 1px solid #ccc;
    border-color: #ccc;
    margin: 0 auto 25px auto;
}

#rgpd tr td {
    text-align: left;
    padding: 8px;
}

#rgpd tr td:first-of-type {
    text-align: right;
    border-right: 1px solid #ccc;
}

@media (max-width: 576px) {
    .foreground .sun {
        width: 40vw;
    }

    .foreground .content-page.content-page-6 {
        padding: 20px;
        width: 100% !important;
    }
}
.slidecontainer {
    width: 50%;
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;
    background: transparent;
    background-image: url("https://sit.arquitectes.cat/2/img/elements/slider_bg.png");
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    background-image: url("https://sit.arquitectes.cat/2/img/elements/slider_thumb.png");
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
}

.slider::-moz-range-thumb {
    width: 40px;
    height: 40px;
    background: #04AA6D;
    cursor: pointer;
}
