.tarjeta {
    color: #666;
}
.nombre-completo {
    color: black;
}

.num-colegiado {
    float: right;
    color: #999;
    font-size: .8em;
}

.tarjeta a {
    color: #000040;
}

.empresas, .formaciones, .link-empresas {
    font-size: .9em;
    padding-top: 8px;
}