.dynamic-form {
  max-width: 1400px;
  margin: auto;
}

.formik-row {
  grid-template-columns: 20% 1fr 35%;
  gap: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
  display: grid;
}

.formik-radio {
  grid-gap: 10px;
  grid-template-columns: 20% 1fr 35%;
  display: grid;
}

.column1 {
  grid-column: 1 / 2;
}

.column2 {
  cursor: pointer;
  grid-column: 2 / 3;
  grid-template-columns: 40px 1fr;
  display: grid;
}

.column3 {
  grid-column: 3 / 4;
}

@media (width <= 800px) {
  .column1, .column2, .bootstrap-select {
    grid-column: 1 / -1;
  }

  .column3 {
    color: #666;
    grid-column: 1 / -1;
    margin-left: 50px;
    font-size: .9em;
  }

  .formik-row {
    margin-bottom: 20px;
  }
}

.form-control.phone {
  max-width: 300px;
}

.form-control.zip_code {
  text-align: center;
  max-width: 120px;
  margin-right: 10px;
}

.lopd {
  margin-bottom: 15px;
}

.lopd .titulo {
  text-align: center;
  padding: 20px 0;
  font-size: 2em;
}

.lopd .tabla-lopd {
  grid-template-columns: 30% 1fr;
  gap: 12px;
  display: grid;
}

.lopd .tabla-lopd .left {
  text-align: right;
  padding-right: 20px;
  font-weight: bold;
}

.lopd .agreement-container {
  justify-content: center;
  padding: 20px 0;
  display: flex;
}

.agreement .form-check {
  white-space: nowrap;
}

.agreement .form-check label {
  white-space: break-spaces;
}

.rdt_TableCol {
  font-weight: bold;
}

.tarjeta {
  color: #666;
}

.nombre-completo {
  color: #000;
}

.num-colegiado {
  float: right;
  color: #999;
  font-size: .8em;
}

.tarjeta a {
  color: #000040;
}

.empresas, .formaciones, .link-empresas {
  padding-top: 8px;
  font-size: .9em;
}

.enviar-sol {
  z-index: -1000;
}

#footer {
  z-index: 1000;
  margin-left: 300px;
}

@media (width <= 1300px) {
  html {
    min-height: 100%;
  }

  body {
    height: 1100px;
  }

  #footer {
    margin-left: 120px;
    margin-right: 70px;
  }

  #header {
    margin-left: 40px;
  }
}

@media (width <= 800px) {
  #footer {
    z-index: 1000;
    margin-left: 40px;
  }

  #header {
    margin-left: 40px;
  }

  #logo {
    display: none;
  }
}

.background {
  overflow-x: hidden;
}

.permanent .sun {
  display: none;
}

.foreground .sun {
  cursor: pointer;
  width: 10vw;
  top: 7%;
  right: 25vw;
  transform: rotate(0);
}

.foreground .sun:hover {
  animation-name: sun;
  animation-duration: 1s;
}

@keyframes sun {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg-element {
  opacity: .75;
  transition: opacity .1s linear;
}

.pag-1.casa .bg-element.casa, .pag-1.edifici .bg-element.edifici, .pag-1.altres .bg-element.altres {
  opacity: 1;
}

.pag-1.casa .bg-element.edifici, .pag-1.casa .bg-element.altres, .pag-1.edifici .bg-element.casa, .pag-1.edifici .bg-element.altres, .pag-1.altres .bg-element.casa, .pag-1.altres .bg-element.edifici {
  opacity: .4;
}

.pag-4 .cases, .pag-4 .edificis, .pag-4 .altres {
  display: none;
}

.pag-4.casa .cases, .pag-4.edifici .edificis, .pag-4.altres .altres {
  display: block;
}

.pag-4 .background .elem {
  opacity: 0;
}

.pag-4 .background.elem1 .elem1, .pag-4 .background.elem2 .elem2, .pag-4 .background.elem3 .elem3, .pag-4 .background.elem4 .elem4, .pag-4 .background.elem5 .elem5 {
  opacity: 1;
}

.foreground .content-page {
  margin-top: -10%;
}

.foreground .content-page.content-page-6 {
  margin-top: 0;
  padding: 50px;
}

.foreground .content-page.content-page-6 h4 {
  margin: 20px 0;
}

.foreground .content-page.content-page-6 button {
  margin-top: 10px;
  margin-bottom: 20px;
}

.form-check-inline {
  margin: .25rem .5rem;
}

.btn.btn-dark.btn-checkbox.selected, .pag-1-radio.btn-check.selected {
  background-color: gray;
}

.btn-container {
  flex-wrap: wrap;
  width: 75vw;
  max-height: 90vw;
  margin: 0 auto;
  overflow: auto;
}

#main {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

input[type="text"]:focus {
  border: 0;
  outline: none;
}

input[type="text"]::placeholder, input[type="email"]::placeholder {
  color: #000;
}

#rgpd {
  border: 1px solid #ccc;
  width: auto;
  margin: 0 auto 25px;
}

#rgpd tr td {
  text-align: left;
  padding: 8px;
}

#rgpd tr td:first-of-type {
  text-align: right;
  border-right: 1px solid #ccc;
}

@media (width <= 576px) {
  .foreground .sun {
    width: 40vw;
  }

  .foreground .content-page.content-page-6 {
    padding: 20px;
    width: 100% !important;
  }
}

.slidecontainer {
  width: 50%;
}

.slider {
  appearance: none;
  -webkit-transition: all .2s;
  background: url("https://sit.arquitectes.cat/2/img/elements/slider_bg.png") 0 0 / 100% 100% no-repeat;
  outline: none;
  width: 100%;
  height: 15px;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  background-image: url("https://sit.arquitectes.cat/2/img/elements/slider_thumb.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
}

.slider::-moz-range-thumb {
  cursor: pointer;
  background: #04aa6d;
  width: 40px;
  height: 40px;
}

/*# sourceMappingURL=index.5b937586.css.map */
